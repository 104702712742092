import React from 'react'
import PropTypes from 'prop-types'
import HeroImage from '../components/HeroImage'
import Layout from '../components/layout'
import SEO from '../components/seo'
import opportunitiesHeroImage from '../components/HeroImage/images/mission-seek-faces-banner.jpg'
import giveHigher from '../images/givehigher_Logo.jpg'

const GiveToMission = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <SEO title="Give to Mission" />
      <HeroImage src={opportunitiesHeroImage} alt="Give to Mission" title="Give to Mission" />
      <div className="container" style={{ textAlign: 'center' }}>
        <a href="https://www.givehigher.com.au/" target="_blank" rel="noopener noreferrer">
          <img style={{ margin: '2px 0 4px 0' }} width="50%" alt="Givehigher" src={giveHigher} />
        </a>
        <p style={{ paddingTop: '10px' }}>Invest in mission projects and Christian workers.</p>

        <figure className="video">
          <iframe
            title="GiveHigher"
            src="https://www.youtube.com/embed/AWYnXpECEZE"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </figure>
        <br />
        <p>
          <a href="https://www.givehigher.com.au/" target="_blank" rel="noopener noreferrer">
            Givehigher
          </a>{' '}
          showcases{' '}
          <a
            href="https://www.givehigher.com.au/projects/"
            target="_blank"
            rel="noopener noreferrer"
          >
            mission projects
          </a>{' '}
          and{' '}
          <a
            href="https://www.givehigher.com.au/christian-workers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Christian workers
          </a>{' '}
          needing financial support. If you want to give to mission, then you will discover all
          sorts of needs at this website. You can search donation needs in numerous ways. For
          example - <strong>type of cause</strong> you may be passionate about (eg- church planting,
          refugee support), <strong>region</strong> (eg- East Africa), and donation amount (eg-
          $25-50)
        </p>
        <br />
        <a
          className="btn btn-primary"
          href="https://www.givehigher.com.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Give to Mission!
        </a>
        <br />
        <br />
        <blockquote>
          &quot;You will be <strong>enriched</strong> in every way so that you can be{' '}
          <strong>generous</strong> on every occasion, and through us your generosity will result in{' '}
          <strong>thanksgiving to God</strong>&quot;. (2 Corinthians 9:11, NIV)
        </blockquote>
        <br />
      </div>
    </Layout>
  )
}

export default GiveToMission

GiveToMission.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
